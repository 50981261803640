import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Spinner, Badge } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { IoMdArrowBack } from "react-icons/io";
import { BiExport } from "react-icons/bi";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import 'react-notifications/lib/notifications.css';
import '../../Components/Table/Table.css';
import appUrl from "../../appUrl";


const ReportFishTable = () => {
    const AppUrl = appUrl();
    const navigate = useNavigate();

    const [dataLoading, setDataLoading] = useState(false);
    const [fishReport, setFishReport] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [tournamentName, setTournamentName] = useState("");
    const tournament_id = localStorage.getItem("tournament");

    const handleGetAllReports = async () => {
        setDataLoading(true);
        const token = localStorage.getItem("token");

        try {
            const response = await fetch(`${AppUrl}/api/v1/report/fish?tournament_id=${tournament_id}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json"
                }
            });

            const data = await response.json();

            if (data.status === true) {
                setTournamentName(data.tournament);
                setFishReport(data.data);
                setTotalCount(data.data.length);
                setDataLoading(false);
            } else {
                NotificationManager.error("Failed to fetch data");
                setDataLoading(false);
            }
        } catch (error) {
            console.log("Error fetching reports:", error);
            NotificationManager.error("Error fetching reports");
            setDataLoading(false);
        }
    };

    useEffect(() => {
        handleGetAllReports();
    }, []);

    const handlePageChange = (page, sizePerPage) => {
        setCurrentPage(page);
        setPageSize(sizePerPage);
    };

    const statusFormatter = (status) => {
        console.log(status, "status");
        switch (status) {
            case "accepted":
                return <Badge bg="success">Accepted</Badge>;
            case "pending":
                return <Badge bg="warning">Pending</Badge>;
            case "rejected":
                return <Badge bg="danger">Rejected</Badge>;
            default:
                return <Badge bg="secondary">Unknown</Badge>;
        }
    };

    // Function to export data to Excel
    const exportToExcel = () => {
        // Prepare the data for export
        const fileName = `${tournamentName}_Fish_Report.xlsx`;

        // Convert the fishReport data to a worksheet
        const worksheet = XLSX.utils.json_to_sheet(fishReport.map(item => ({
            "Angler": item.angler_name || "-",
            "Species": item.species_name || "-",
            "Weight": item.fish_weight || "-",
            "Length": item.fish_length || "-",
            "Points": item.total_points || "-",
            "Location": item.location || "-",
            "Guide": item.guide_name || "-",
            "Team": item.team_name || "-",
            "Status": statusFormatter(item.status) || "Unknown",
        })));

        // Create a workbook and append the worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Fish Report");

        // Write the file and prompt download
        XLSX.writeFile(workbook, fileName);
    };

    const columns = [
        {
            formatter: (cell, row, rowIndex) => pageSize * (currentPage - 1) + rowIndex + 1,
            dataField: 'index',
            text: 'S No.',
            headerClass: 'serial-number-header ',
            classes: 'serial-number-column',
        },
        {
            dataField: 'angler_name',
            text: 'Angler',
            formatter: (cell) => cell || "-",
            filter: textFilter({ placeholder: '' }),
        },
        {
            dataField: 'species_name',
            text: 'Species',
            formatter: (cell) => cell || "-",
            filter: textFilter({ placeholder: '' }),
            classes: 'email-column',
        },
        {
            dataField: 'fish_weight',
            text: 'Weight',
            formatter: (cell) => cell || "-",
            filter: textFilter({ placeholder: '' }),
        },
        {
            dataField: 'fish_length',
            text: 'Length',
            formatter: (cell) => cell || "-",
            filter: textFilter({ placeholder: '' }),
        },
        {
            dataField: 'total_points',
            text: 'Points',
            formatter: (cell) => cell || "-",
            filter: textFilter({ placeholder: '' }),
        },
        {
            dataField: 'fishing_style',
            text: 'Tackle type',
            formatter: (cell) => cell || "-",
            filter: textFilter({ placeholder: '' }),
        },
        {
            dataField: 'location',
            text: 'Location',
            formatter: (cell) => cell || "-",
            filter: textFilter({ placeholder: '' }),
        },
        {
            dataField: 'guide_name',
            text: 'Guide',
            formatter: (cell) => cell || "-",
            filter: textFilter({ placeholder: '' }),
        },
        {
            dataField: 'team_name',
            text: 'Team',
            formatter: (cell) => cell || "-",
            filter: textFilter({ placeholder: '' }),
        },
        {
            dataField: 'captured',
            text: 'Date/Time',
            formatter: (cell) => {
                if (!cell) return "-";
        
                const date = new Date(cell);
                const formattedDate = date.toLocaleDateString('en-GB'); // Formats as DD/MM/yyyy
                const formattedTime = date.toLocaleTimeString('en-US', {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false, // Use 24-hour format
                });
        
                return `${formattedTime} ${formattedDate} `;
            },
            filter: textFilter({ placeholder: '' }),
        },
        {
            dataField: 'status',
            text: 'Status',
            formatter: (cell) => statusFormatter(cell || ""),
            filter: textFilter({ placeholder: '' }),
        }
    ];

    return (
        <>
            <NotificationContainer />

            <div className="d-flex justify-content-between mb-4">
                <IoMdArrowBack className='report-icon' onClick={() => navigate(`/user-login/publish/report/${tournament_id}`)} />
                <h4 style={{ fontWeight: 600 }}>{tournamentName}</h4>
                <Button className='export-button' onClick={exportToExcel}>
                    Export <BiExport />
                </Button>
            </div>

            {dataLoading ? (
                <div className='loading-spinner'>
                    <Spinner animation="border" role="status" />
                </div>
            ) : (
                <BootstrapTable
                    bootstrap4
                    keyField="_id"
                    data={fishReport}
                    columns={columns}
                    filter={filterFactory()}
                    pagination={paginationFactory({
                        page: currentPage,
                        sizePerPage: pageSize,
                        totalSize: totalCount,
                        onPageChange: handlePageChange,
                        sizePerPageRenderer: () => null,
                        showTotal: true,
                        paginationTotalRenderer: (from, to, total) => (
                            <div className="pagination-and-entries">
                                <p className="entries">
                                    Showing {from} to {to} of {total} entries
                                </p>
                            </div>
                        ),
                    })}
                />
            )}
        </>
    );
};

export default ReportFishTable;