import React, { useState, useEffect } from 'react';
import { Button, Table,Card} from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { IoArrowBack } from "react-icons/io5";
import "../publish.css";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import appUrl from '../../../appUrl';

const ReportResult = () => {
  const { id } = useParams();
  console.log(id, "paramssss")
  const navigate = useNavigate();
  const [report, setReport] = useState([]);
  const AppUrl = appUrl();

  const tournament_id = localStorage.getItem("tournament")

  useEffect(() => {
    getReportList();
  }, [id]);

  // const handleSubmission = async () => {
  //   setLoading(true);
  //   const fishingToken = localStorage.getItem('token');
  //   const tournament_id = localStorage.getItem('tournament');
  //   const url = `${AppUrl}/api/v1/report/create`;

  //   const values = {
  //     "report_name": "All selected",
  //     "participants": [
  //         "all"
  //     ],
  //     "range": [
  //         "all"
  //     ],
  //     "guided": "guided",
  //     "tackle_type": [
  //         "6751b069b165a42ebf4a106c",
  //         "6751b069b165a42ebf4a106d",
  //         "6751b069b165a42ebf4a106e"
  //     ],
  //     "species": [
  //         "6751b069b165a42ebf4a1071",
  //         "6751b069b165a42ebf4a1072",
  //         "6751b069b165a42ebf4a1073"
  //     ],
  //     "species_number": null,
  //     "species_stat": "length",
  //     "first_catch": false,
  //     "public": false,
  //     "multipliers": false,
  //     "largest_species": false,
  //     "capture_date": "",
  //     "special_category": [
  //         "6751a996d098a22ec6b87efd",
  //         "6751a996d098a22ec6b87efe"
  //     ],
  //   }
  //   try {
  //     const response = await fetch(url, {
  //       method: "POST",
  //       headers: {
  //         Authorization: `Bearer ${fishingToken}`,
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({
  //         ...values,
  //         tournament: tournament_id,
  //         tournament_id: id
  //       }),
  //     });
  //     const data = await response.json();
  //     if (response.ok && data.status === true) {
  //       localStorage.setItem("report", data.report._id);
  //       navigate("/user-login/publish/report");
  //     } else {
  //       NotificationManager.error(data.message || 'Failed to refresh report.');
  //     }
  //   } catch (error) {
  //     NotificationManager.error(error.message || 'An unexpected error occurred.');
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const getReportList = async () => {
    const fishingToken = localStorage.getItem("token");
    try {
      const response = await fetch(`${AppUrl}/api/v1/report?report_id=${id}`, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${fishingToken}`,
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (response.ok && data.data) {
        setReport(data.data.report);  // Corrected: set data.result directly
      } else {
        NotificationManager.error('Failed to fetch report list');
      }
    } catch (error) {
      NotificationManager.error('Error fetching report list');
    }
  };

  console.log(report, "reports");

  return (
    <>
      <NotificationContainer />
      <div className='report-title'>
        <IoArrowBack onClick={() => navigate(`/user-login/publish/report/${tournament_id}`)} className='back-arrow' />
        <h3>Result</h3>
      </div>

      <Card className="report-card">
        <Card.Body>
          <h4 className='mb-3'>Report Information</h4>
          <div className="report-details">
            <p><strong>Report Name:</strong> {report.report_name}</p>
            <p><strong>Tournament Name:</strong> {report.tournament}</p>
            <p><strong>Participants:</strong> {report.participants}</p>
            <p><strong>Range:</strong> {report.range}</p>
            <p><strong>Guided:</strong> {report.guided}</p>
            <p><strong>Tackle Type:</strong> {report.tackle_type}</p>
            <p><strong>Species:</strong> {report?.species?.join(', ')}</p>
            <p><strong>Species Number:</strong> {report.species_number}</p>
            <p><strong>Species Stat:</strong> {report.species_stat}</p>
            <p><strong>Special Category:</strong> {report?.special_category?.join(', ')}</p>
            <p><strong>First Catch:</strong> {report.first_catch ? "Yes" : "No"}</p>
            <p><strong>Public:</strong> {report.public ? "Yes" : "No"}</p>
            <p><strong>Multipliers:</strong> {report.multipliers ? "Yes" : "No"}</p>
            <p><strong>Largest Species:</strong> {report.largest_species ? "Yes" : "No"}</p>
            <p><strong>Capture Date:</strong> {report.capture_date || "-"}</p>
          </div>
        </Card.Body>
      </Card>

      {report?.result?.length > 0 ? (
        <Table striped bordered hover responsive className='mt-5'>
          <thead>
            <tr>
              <th>Rank</th>
              <th>Participant</th>
              <th>Species</th>
              <th>Species Count</th>
              {report.stats === "points" && (<th>Points</th>)}
              {report.stats === "weight" && (<th>Weight</th>)}
              {report.stats === "length" && (<th>Length</th>)}
              <th>Guide</th>
              <th>Team</th>
            </tr>
          </thead>
          <tbody>
            {report?.result?.map((rep, index) => (
              <tr key={rep._id}>
                <td>{index + 1}</td>
                <td>{rep.participant_name}</td>
                <td>{rep.species_name || "-"}</td>
                <td>{rep.total_species || "-"}</td>
                {report.stats === "points" && (
                  <td>{rep.total_points || "-"}</td>
                )}
                {report.stats === "weight" && (
                  <td>{rep.total_weight || "-"}</td>
                )}
                {report.stats === "length" && (
                  <td>{rep.total_length || "-"}</td>
                )}
                <td>{rep.guide || "-"}</td>
                <td></td>
              </tr>
            ))}
          </tbody>
        </Table>

      ) : (
        <p className='text-center mt-5'>You have not added any Report for this tournament. Add to proceed</p>
      )}
    </>
  );
};

export default ReportResult;
