import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Row, Col, Modal, ModalHeader, ModalBody, Spinner, Badge } from 'react-bootstrap';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import appUrl from '../../appUrl';
import "./publish.css";
import { SlCalender } from "react-icons/sl";
import { PiClockBold } from "react-icons/pi";
import { IoSearch } from "react-icons/io5";

const Publish = () => {
  const AppUrl = appUrl();
  const navigate = useNavigate()

  const [publishModal, setPublishModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  console.log(publishModal, "publishModal")

  console.log("publish-page");
  const [isPublish, setIsPublish] = useState(true);  // Initial state for published tournaments
  const [fetched, setFetched] = useState([]);
  const [dataLoading, setDataLoading] = useState(false)

  // Fetch tournaments based on publish state and timezone
  const handleGetAllTournaments = async () => {
    setDataLoading(true)
    const token = localStorage.getItem("token");
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    try {
      const response = await fetch(`${AppUrl}/api/v1/tournament/get-all?publish=${isPublish}`, {
        method: "POST",  // Changed to POST to allow sending data in the body
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          time_zone: timeZone  // Sending timezone in the body
        })
      });

      const data = await response.json();  // Parse the response as JSON
      if (data.status === true) {
        setFetched(data.data);
        setDataLoading(false)
      }
    } catch (error) {
      console.log("Error fetching tournaments:", error);
    }
  };

  // Set to true for published tournaments
  const handlePublish = () => {
    console.log("Publish clicked");
    setIsPublish(true);
  };

  // Set to false for unpublished tournaments
  const handleUnpublish = () => {
    console.log("Unpublish clicked");
    setIsPublish(false);
  };

  // Fetch tournaments when publish state changes
  useEffect(() => {
    handleGetAllTournaments();
  }, [isPublish]);

  // Function to handle "Publish Later"
  const handleLater = () => {
    setPublishModal(false)
  };


  const handleDeleteNow = async () => {
    try {
      const deleleId = localStorage.getItem("tournament")
      const token = localStorage.getItem("token")
      const response = await fetch(`${AppUrl}/api/v1/tournament?id=${deleleId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "Application-json"
          }
        }
      );
      const data = await response.json();
      if (data.code === 200) {
        setDeleteModal(false)
        NotificationManager.success("Tournament deleted successfully")
        localStorage.removeItem("publishId")
        handleGetAllTournaments()
      } else {
        setDeleteModal(false)
        NotificationManager.error(data.message)
      }
    } catch (error) {

    }
  }
  // Function to handle "Publish Now"
  const handlePublishNow = async () => {
    try {
      const fishingToken = localStorage.getItem("token");
      const tournament = localStorage.getItem("tournament");

      if (!fishingToken || !tournament) {
        alert("Authentication token or Tournament ID is missing.");
        return;
      }

      const response = await fetch(`${AppUrl}/api/v1/tournament/publish?tournamentId=${tournament}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${fishingToken}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ publish: true }) // Changed "true" to boolean true
      });

      const data = await response.json();

      if (response.ok) {
        console.log("Tournament published successfully:", data);
        setPublishModal(false)
        window.location.reload()
      } else {
        console.error("Failed to publish tournament:", data);
        // alert(`Error: ${data.message || 'Failed to publish tournament'}`);
      }
    } catch (error) {
      console.error("Error publishing tournament:", error);
      alert(`Error: ${error.message || 'Failed to publish tournament'}`);
    }
  };

  return (
    <>
      <NotificationContainer />
      <div>
        {/* Using Buttons instead of Nav for toggling */}
        <div className="d-flex justify-content-between align-items-center my-3">
          <div className="d-flex my-3 publish-title">
            <Button variant={isPublish ? "selected" : ""} onClick={handlePublish}>
              Publish
            </Button>
            <Button variant={!isPublish ? "selected" : ""} onClick={handleUnpublish} className="ml-2">
              Unpublished
            </Button>
          </div>
          <div className='search-main'><IoSearch /> Search</div>
        </div>

        {
          !dataLoading ? (
            fetched.length > 0 ? (  // Check if there are fetched tournaments
              <Row>
                {fetched.map((pub, id) => (
                  <Col key={id} md={6}> {/* Each card will take up half the row width */}
                    <div className=' publish-main mb-3'>

                      <div className='publish-sub'>
                        <h4>{pub.tournament_name}</h4>
                        <p><SlCalender /> {pub.start_date} to {pub.end_date}</p>
                        <p><PiClockBold /> {pub.lines_in} to {pub.lines_out}</p>

                        <div className='d-flex mb-3'>
                          {/* <Button variant='light' className='publish-details-button'
                            onClick={() => navigate(`/user-login/tournament-table/${pub._id}`)}
                          >View</Button> */}
                          <Button variant='light' className='publish-details-button' 
                          onClick={() => {
                            navigate(`/user-login/tournament-table/${pub._id}`);
                            localStorage.setItem("isEditing", "true");
                            localStorage.setItem("tournament", pub._id);
                          }}
                          >Edit</Button>
                          <Button variant='light' className='publish-details-button'
                            onClick={() => {
                              localStorage.setItem("tournament", pub._id);
                              setDeleteModal(true)
                            }}
                          >Delete</Button>
                        </div>

                        {isPublish ? (
                          <Button variant='light' className='create-report-button'
                            onClick={() => {
                              navigate(`/user-login/publish/report/${pub._id}`);
                              // localStorage.setItem("tournament", pub._id);
                            }}
                          > Reports</Button>
                        ) : (
                          <Button variant='light' className='create-report-button'
                            onClick={() => { setPublishModal(true); localStorage.setItem("tournament", pub._id) }}
                          > Publish</Button>
                        )}
                      </div>

                      <div className="d-flex justify-content-between align-items-center"  >
                        {pub.upcoming === false ? (<Badge bg="success">Active</Badge>) : ("")}
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            ) : (
              <p className='mt-5' style={{ textAlign: "center", fontWeight: 600 }}>You have not created any tournament yet. Create to proceed</p>  // Display message if no tournaments exist
            )
          ) : (
            <div className='d-flex justify-content-center'>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className='delete-spinner'
              />
            </div>
          )
        }


      </div>

      {publishModal &&
        <Modal
          show={publishModal}
          onHide={() => setPublishModal(false)}
          backdrop="static"
          centered
          className='submission-modal'
        >
          <ModalHeader closeButton></ModalHeader>
          <ModalBody>
            <h3 className='publish-title'>Are you sure you want to publish the tournament?</h3>
            <div className="d-flex justify-content-center">
              <Button className='publish-later' onClick={handleLater}>
                Publish Later
              </Button>
              <Button className='publish-now' onClick={handlePublishNow}>
                Publish Now
              </Button>
            </div>
          </ModalBody>
        </Modal>
      }

      {deleteModal &&
        <Modal
          show={deleteModal}
          onHide={() => setDeleteModal(false)}
          centered
          backdrop="static"
          className='submission-modal'
        >
          <ModalHeader className='publish-title'>Are you sure you want to delete this tournament?</ModalHeader>
          <ModalBody>
            <div className="d-flex justify-content-center ">
              <Button className='publish-later' onClick={() => { setDeleteModal(false); localStorage.removeItem("tournament") }}>Cancel</Button>
              <Button className='publish-now' style={{ backgroundColor: "#d91818", borderColor: "#d91818" }}
                onClick={handleDeleteNow}
              >Delete</Button>
            </div>
          </ModalBody>
        </Modal>}

    </>
  );
};

export default Publish;
