import React from 'react';
import { Routes, Route } from 'react-router-dom';
import EditTour from '../../Components/Publish/EditTour';
import Publish from '../../Components/Publish/Publish';
import TournamentDetails from '../../Components/Table/TournamentDetails';
import Report from '../../Components/Publish/Report/Report';
import CreateReport from '../../Components/Publish/Report/CreateReport';
import ReportResult from '../../Components/Publish/Report/ReportResult';

const PublishTournament = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Publish />} />
        <Route path="/edit" element={<EditTour />} />
        <Route path="/report/:id" element={<Report />} />
        <Route path="/create-report" element={<CreateReport/>} />
        <Route path="/create-report/:id/:edit" element={<CreateReport/>} />
        <Route path="/result/:id" element={<ReportResult/>} />
      </Routes>
    </div>
  )
}

export default PublishTournament
