import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { IoMdArrowBack } from "react-icons/io";
import { BiExport } from "react-icons/bi";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import 'react-notifications/lib/notifications.css';
import '../../Components/Table/Table.css';
import appUrl from "../../appUrl";

const UserTable = () => {
    const AppUrl = appUrl();
    const navigate = useNavigate();

    const [dataLoading, setDataLoading] = useState(false);
    const [userReport, setUserReport] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [tournamentName, setTournamentName] = useState("");
    const tournament_id = localStorage.getItem("tournament");

    const handleGetAllReports = async () => {
        setDataLoading(true);
        const token = localStorage.getItem("token");

        try {
            const response = await fetch(`${AppUrl}/api/v1/report/user?tournament_id=${tournament_id}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json"
                }
            });

            const data = await response.json();

            if (data.status === true) {
                setTournamentName(data.tournament_name);
                setUserReport(data.data);
                setTotalCount(data.data.length);
                setDataLoading(false);
            } else {
                NotificationManager.error("Failed to fetch data");
                setDataLoading(false);
            }
        } catch (error) {
            console.log("Error fetching reports:", error);
            NotificationManager.error("Error fetching reports");
            setDataLoading(false);
        }
    };

    useEffect(() => {
        handleGetAllReports();
    }, []);

    const handlePageChange = (page, sizePerPage) => {
        setCurrentPage(page);
        setPageSize(sizePerPage);
    };

    const exportToExcel = () => {
        const fileName = `User_Report_${tournamentName}.xlsx`;
        const worksheet = XLSX.utils.json_to_sheet(userReport);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "UserReport");
        XLSX.writeFile(workbook, fileName);
    };

    const columns = [
        {
            formatter: (cell, row, rowIndex) => pageSize * (currentPage - 1) + rowIndex + 1,
            dataField: 'index',
            text: 'S No.',
            headerClass: 'serial-number-header ',
            classes: 'serial-number-column',
        },
        {
            dataField: 'name',
            text: 'Name',
            formatter: (cell) => cell || "-",
            filter: textFilter({ placeholder: '' }),
        },
        {
            dataField: 'email',
            text: 'Email',
            formatter: (cell) => cell || "-",
            filter: textFilter({ placeholder: '' }),
            classes: 'email-column',
        },
        {
            dataField: 'gender',
            text: 'Gender',
            formatter: (cell) => cell || "-",
            filter: textFilter({ placeholder: '' }),
        },
        {
            dataField: 'date_of_birth',
            text: 'D.O.B',
            formatter: (cell) => cell || "-",
            filter: textFilter({ placeholder: '' }),
        },
        {
            dataField: 'mobileNumber',
            text: 'Mobile Number',
            formatter: (cell) => cell || "-",
            filter: textFilter({ placeholder: '' }),
        },
        {
            dataField: 'address',
            text: 'Address',
            formatter: (cell) => cell || "-",
            filter: textFilter({ placeholder: '' }),
        },
        {
            dataField: 'team_member_type',
            text: 'Ps type',
            formatter: (cell) => cell.join(', '),
            filter: textFilter({ placeholder: '' }),
        },
        {
            dataField: 'team_name',
            text: 'Team',
            formatter: (cell) => cell || "-",
            filter: textFilter({ placeholder: '' }),
        },
        {
            dataField: "category",
            text: 'Spl Category',
            formatter: (cell) => cell || "-",
            filter: textFilter({ placeholder: '' }),
        },
        {
            dataField: 'shirt_size',
            text: 'Shirt size',
            formatter: (cell) => cell || "-",
            filter: textFilter({ placeholder: '' }),
        },
    ];

    return (
        <>
            <NotificationContainer />

            <div className="d-flex justify-content-between mb-4">
                <IoMdArrowBack className='report-icon' onClick={() => navigate(`/user-login/publish/report/${tournament_id}`)} />
                <h4 style={{ fontWeight: 600 }}>{tournamentName}</h4>
                <Button className='export-button' onClick={exportToExcel}>Export <BiExport /></Button>
            </div>

            {dataLoading ? (
                <div className='loading-spinner'>
                    <Spinner animation="border" role="status" />
                </div>
            ) : (
                <BootstrapTable
                    bootstrap4
                    keyField="_id"
                    data={userReport}
                    columns={columns}
                    filter={filterFactory()}
                    pagination={paginationFactory({
                        page: currentPage,
                        sizePerPage: pageSize,
                        totalSize: totalCount,
                        onPageChange: handlePageChange,
                        sizePerPageRenderer: () => null,
                        showTotal: true,
                        paginationTotalRenderer: (from, to, total) => (
                            <div className="pagination-and-entries">
                                <p className="entries">
                                    Showing {from} to {to} of {total} entries
                                </p>
                            </div>
                        ),
                    })}
                />
            )}
        </>
    );
};

export default UserTable;
